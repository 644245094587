@import "~bootstrap/dist/css/bootstrap.css";
@import 'vars';
@import 'mixins';

body {
  background: $c-neutral-off-white;
  font-family: $f-rr;
  color: #3F3356;
  font-size: 8px;
  line-height: 140%;

  @include media_for(desktop) {
    font-size: 13px;
    line-height: 150%;
  }
}

* {
  outline: none;
}

.main {
  width: 100%;
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  display: inline-block;
  position: relative;

  @include media_for(desktop) {
    width: calc(100vw - 140px);
    margin-left: 140px;
  }
}

section {
  padding: 20px;

  @include media_for(desktop) {
    padding: 50px;
  }
}

.row {
  margin: 0;
  max-width: 100%;

  &> div {
    padding: 0;
    margin: 0;
  }
}

.offset-1 {
  margin-left: 8.333333% !important;
}

.offset-2 {
  margin-left: 16.666667% !important;
}

.photo {
  border-radius: 100%;
  overflow: hidden;
  height: 30px;
  width: 30px;
  position: relative;

  @include media_for(desktop) {
    height: 45px;
    width: 45px;
  }

  img {
    @include abs_center(100%, auto);
    display: block;
  }
}



.p-autocomplete, .p-dropdown {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: $c-neutral-off-white;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 43px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $c-primary-light-green;
  }

  .p-autocomplete-panel, .p-dropdown-panel {
    background: white;
    margin-top: 10px;
    border: 1px solid #F1F2F2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    overflow: hidden !important;
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0 35px !important;
  }

  .p-autocomplete-items, .p-dropdown-items {
    overflow-y: scroll;
    max-height: 220px;
    color: $c-neutral-grey;
    font-size: 12px;
    line-height: 33px;
    padding: 15px 10px 35px !important;
    background: white;

    li {
      padding: 0px 10px !important;

      &:hover {
        background: $c-neutral-off-white;
      }
    }
  }
}
.reportLink {
  a {
    color: #8ec640 !important;
    &:link {
        color: #8ec640;
        text-decoration: none;
      }
    
    &:active {
      color: #2C6245 !important;
    }
  }
}