@import 'vars';
@import 'mixins';
@import 'fonts';
@import 'backgrounds';
@import 'general';
@import 'tab';
@import 'top-header';
@import 'menu';
@import 'table';
@import 'form';
@import 'login';
@import 'view-advisor';
@import 'modal';
@import '~primeng/resources/components/calendar/calendar.css';
@import 'date-picker';


.table .table-1 tbody tr td .bordered {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.table .dataTables_paginate {
    margin-bottom: 25px;
}

@media screen
  and (min-width: 860px){
    .main{
        margin-left: 140px;
        width: calc(100vw - 140px);
    }

    header .user-data {
        width: calc(100vw - 140px);
        padding: 20px 55px 20px 0px;
    }
}

@media screen
  and (min-width: 860px) and (max-width: 1023px){
    .login {
        background: linear-gradient(143.56deg, rgba(103, 191, 198, 0.8) 3.99%, rgba(142, 198, 64, 0.8) 113.8%);
        min-height: 100vh;
    }

    .login .col-half .btn {
        background: #ffffff !important;
    }

    .login .form-group {
        margin: auto;
    }
}

#dashboard .title{
    line-height: 1;
}

.login .col-half{
    overflow: hidden;
}

.table .table-1 {
    width: 100% !important;
}


.bordered{
    border-top: 1px solid #D1D3D4;
    border-bottom: 1px solid #D1D3D4;
    height: 75px;
    display: flex;
    padding-right: 15px;
    width: 100%;
}

.bordered *{
  margin-top: auto;
  margin-bottom: auto;
}

 tbody tr td:first-of-type .bordered {
  padding-left: 30px;
  border-left: 1px solid #D1D3D4;
  padding-right: 0;
  margin-left: 30px;
}
tbody{
  padding: 0 30px !important;
}
  tbody tr td:last-of-type .bordered {
  border-right: 1px solid #D1D3D4;
  padding-left: 0;
  padding-right:0;
  width: 85%;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0 !important;
}

td{
  border: none !important;


}

  tbody tr:first-of-type td .bordered {
  margin-top: 30px;
}

  tbody tr:last-of-type td .bordered {
  margin-bottom: 30px;
}

 thead tr th:first-of-type p-tableheadercheckbox{
  margin-left: 30px !important;
}

 thead tr th:last-of-type{
  padding-right: 30px !important;
}

 thead tr th{
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

thead tr th:first-of-type{
  padding-left: 30px !important;
}

 .p-paginator {
  display: flex;
  align-items: end !important;
  justify-content: end !important;
  flex-wrap: wrap;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight, .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover  {
    background: #8ec640 !important;
    border-color: #8ec640 !important;
    color: white !important;
    font-weight: bold;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #8ec640 !important;
    border-color: #8ec640 !important;
    color: white !important;
    font-weight: bold;
}

table, input, .p-inputtext {
  font-family: Roboto-Regular !important;
}

th{
  font-size: 11px;
  font-weight: 100 !important;
}

td{
  font-size: 12px;
}

.new{
  font-family: 'Roboto-Regular';
  font-size: 12px;
}

strong{
  font-family: 'Roboto-Bold';
}

p-paginator button{
  font-size: 10px !important;
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
  line-height: 16px !important;
  border: 2px solid #8ec640 !important;
}

p-paginator span.pi{
  font-size: 10px;
  margin-top: 2px;
}

.action-button{
  text-transform: capitalize !important;
  font-family: 'Roboto-Regular';
  font-weight: 400 !important;
  color: #808285 !important;
  background-color: white !important;
}

.action-button:hover{
  background: #8ec640 !important;
  color: #ffffff !important;
  border-color: #8ec640 !important;
}

.p-paginator-bottom{
  background: transparent !important;
}

.p-paginator{
  border: none !important;
  padding: 0.5rem 0 !important;
}

.p-component{
  font-family: $f-rr !important;
}

.p-menu{
  width: auto !important;
  .p-menu-list{
  font-size: 12px;
  width: auto;
  }
  .p-menuitem-link{
    padding: 10px 1rem !important;
  }

  .p-menuitem-link:not(.p-disabled):hover{
    background: #8ec640 !important;
    .p-menuitem-text{
      color: white !important;
    }

  }


  li span{
    margin: auto;
  }

  .p-submenu-header{
    padding: 0 !important
  }
}

table tbody tr td {
  color:#808285;
}

table tbody tr td strong{
  color:#414042;
}

p-sorticon{
  .p-sortable-column-icon{
    font-size: 11px;
  }
  sortalticon{
    display: none !important;
  }
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 4px !important;
}

.p-element .p-checkbox .p-checkbox-box {
    width: 13px;
    height: 13px;
  }

  td p, td strong{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  .p-checkbox-box{
    margin-top: auto;
    margin-bottom: auto;
  }

.p-tooltip.p-component {
  font-size: 12px !important;
    font-family: Roboto-Regular;
}

.action{
 /* display: block !important;*/
  text-align: center !important;
}

.rps-link{
  .max-content{
    margin: auto;
  }
}

.button-archive{
  font-size: 12px;
}



.p-datatable .p-sortable-column.p-highlight {
  color: #50575e !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 16px !important;
  height: 16px !important;
  left: -1px !important;
  margin-top: -8px !important;
}

.p-inputswitch {
  width: 36px !important;
  height: 18px !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #F1F2F2 !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #BFDB8F !important;
}

.p-inputswitch .p-inputswitch-slider {
  background: #F1F2F2 !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background:#dc3545 !important;
}

@media only screen and (max-width: 1023px){
  td{
    font-size: 8px !important;
  }

  th{
    font-size: 8px !important;
    font-weight: 100 !important;
  }

  p-table p-paginator button{
    font-size: 8px !important;
    width: 15px !important;
    min-width: 15px !important;
    height: 15px !important;
    line-height: 16px !important;
    border: 2px solid #8ec640 !important;
  }

  .action-button{
    font-size: 8px !important;
  }

  .new{
    em{
      font-size: 12px !important;
    }
     span{
      font-size:8px !important;
      top: -1px !important;
    }
  }

  .p-menu{
    .p-menu-list{
    font-size: 8px !important;
    }
    .p-menuitem-link{
      padding: 5px 1rem !important;
    }
  }

  .button-archive{
    font-size: 8px;
    margin-bottom: 5px !important;
  }

  .p-element .p-checkbox .p-checkbox-box {
    width: 8px;
    height: 8px;
  }
}

table .p-checkbox .p-checkbox-box .p-checkbox-icon{
  display: none;
}

table .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}

table .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #8ec640;
  background: #8ec640;
}

table .p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da !important;
  border-radius: 0 !important;
}


.p-dialog .p-dialog-content {
  background: transparent !important;
}
button.export {
  font-size: 12px;
  width: 140px !important;
}


input[type="date"] {
  position: relative;
  width: 100%; height: 30px;
  color: white;
}

input[type="date"]:before {
  position: absolute;
  top: 3px; left: 3px;
  content: attr(data-date);
  display: inline-block;
  color: black;
  width: 100%;
}

input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 3px;
  right: 0;
  color: black;
  opacity: 1;
}
.pi-search{
  z-index: 9;
}

.p-inputtext:enabled:focus, .p-inputtext:enabled:hover  {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: #8ec640 !important;
}

.spinner-div{
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  background: #80808066;
  z-index: 9999;
  p-progressspinner{
    left: 50%;
    position: absolute;
    top: 45%;
  }
  .spinner-div__custom-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
  }
}



@keyframes custom-progress-spinner-color {
  100%,
  0% {
      stroke: #16697A;
  }
  40% {
      stroke: #489FB5;
  }
  66% {
      stroke: #82C0CC;
  }
  80%,
  90% {
      stroke: #FFA62B;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }
  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}

body, html{
  overflow-x: hidden;
}

.questionnaire-box{
  background-color: white !important;
  background: white !important;
}

.not-overflow{
  overflow: hidden !important;
}
