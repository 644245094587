@import 'vars';
@import 'mixins';

app-clients-view-advisor-header {
  width: 100%;
}

.view-advisor {
  padding: 15px;
  background: $c-white;
  border: 0.5px solid $c-neutral-20;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  @include media_for(desktop) {
    padding: 25px 30px;
  }

  .offset-1 {
    color: $c-neutral-grey;
  }

  .col-7 {
    .col-8 {
      @include media_for(desktop) {
        margin-left: -15%;
      }
    }
  }

  .principal-score {
    .row {
      justify-content: flex-end;

      .col-6 {
        @include media_for(desktop) {
          max-width: 75px;
        }

        &:last-of-type {
          @include media_for(desktop) {
            margin-left: 30px;
          }
        }
      }
    }
  }

  h2 {
    font-size: 10px;
    text-align: center;
    color: $c-neutral-grey;
    font-weight: bold;
  }

  .circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media_for(desktop) {
      width: 70px;
      height: 70px;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      color: $c-white;

      @include media_for(desktop) {
        font-size: 30px;
      }
    }
  }

  .bars {
    margin-top: 15px;

    @include media_for(desktop) {
      margin-top: 30px;
    }

    div {
      width: 20%;
      display: inline-block;
      text-align: center;
      padding: 1px 0;
      font-weight: bold;

      @include media_for(desktop) {
        padding: 3px 0;
      }
    }
  }

  .bar-table {
    margin-top: 10px;
    padding-bottom: 5px;
    border-bottom: 0.5px solid $c-neutral-40;

    @include media_for(desktop) {
      margin-top: 30px;
    }

    div {
      color: $c-neutral-grey;
      display: inline-block;
      cursor: pointer;
      font-weight: bold;

      em {
        color: $c-primary-light-green;
        font-size: 6px;

        @include media_for(desktop) {
          font-size: 8px;
          position: relative;
          top: -1px;
        }
      }


      &.type {
        width: 60px;
        float: right;

        @include media_for(desktop) {
          width: 165px;
        }

        &.single {
          width: 30px;

          @include media_for(desktop) {
            width: 82px;
            text-align: center;
          }

          .date {
            width: 100%;
          }
        }

        div {
          width: 50%;
          display: inline-block;
          text-align: center;

          &.date {
            color: $c-neutral-60;

            @include media_for(mobile) {
              font-size: 6px;
            }
          }
        }
      }
    }
  }

  .records {
    margin-top: 7px;

    .record {
      margin: 2px 0px;
      display: flex;
      position: relative;

      @include media_for(desktop) {
        margin: 3px 0;
      }

      div {
        display: inline-flex;
      }

      .content {
        position: relative;
        width: calc(100% - 62px);
        height: 100%;
        display: flex;
        align-items: center;
        border: 0.5px solid $c-neutral-20;

        @include media_for(desktop) {
          width: calc(100% - 175px);
        }

        &.single {
          width: calc(100% - 30px);

          @include media_for(desktop) {
            width: calc(100% - 90px);
          }
        }

        .number {
          color: $c-neutral-grey;
          border-right: 0.5px solid $c-neutral-20;
          padding: 10px;
          background: $c-neutral-off-white;
          align-self: stretch;
          width: 36px;
          justify-content: center;
        }

        .text {
          padding: 10px;
          width: 100%;
          flex-direction: column;

          div {
            font-weight: bold;
          }

          ul {
            position: relative;
            margin: 20px auto 0px;
            width: 100%;
            padding-left: 15px;
            color: $c-neutral-60;
            font-weight: normal;

            &:before {
              @include abs_center(calc(100% + 20px), 1px);
              content: '';
              background: $c-neutral-20;
              bottom: inherit;
              width: calc(100% + 20px);
              left: -10px;
              top: -10px;
            }
          }
        }
      }

      .score {
        @include abs_center(60px, 100%);
        left: inherit;
        display: block;

        @include media_for(desktop) {
          width: 165px;
        }

        &.single {
          width: 30px;

          @include media_for(desktop) {
            width: 82px;
          }
        }

        div {
          padding: 0;
          width: 28px;
          height: 100%;
          font-size: 9px;
          font-weight: bold;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          @include media_for(desktop) {
            width: 80px;
            font-size: 13px;
          }

          &:first-of-type {
            margin-right: 2px;

            @include media_for(desktop) {
              margin-right: 5px;
            }
          }

          &.assign {
            border: 0.5px solid #D1D3D4;

            select {
              background: transparent;
              color: $c-neutral-black;
              font-weight: bold;
              width: 100%;
              text-align-last: center;
              height: 100%;
              padding: 0 20px;
              border: 0;

              option {
                text-align-last: center;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}
