@import 'vars';

.bg-s-orange, .bg-2 {
  background: $c-primary-soft-orange;
  color: $c-white;
}

.bg-orange, .bg-1 {
  background: $c-primary-orange;
  color: $c-white;
}

.bg-s-green, .bg-3 {
  background: $c-primary-soft-green;
  color: $c-white;
}

.bg-l-green, .bg-4 {
  background: $c-primary-light-green;
  color: $c-white;
}

.bg-green, .bg-5 {
  background: $c-primary-medium-green;
  color: $c-white;
}

.bg-neutral-40 {
  background: $c-neutral-40;
}
