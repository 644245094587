@import 'vars';
@import 'mixins';

.login {
  padding: 0;

  @include media_for(mobile) {
    background: linear-gradient(143.56deg, rgba(103, 191, 198, 0.8) 3.99%, rgba(142, 198, 64, 0.8) 113.8%);
    min-height: 100vh;
  }

  .col-half {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;

    @include media_for(desktop) {
      min-height: 100vh;
      max-height: 100vh;
      max-width: 50% !important;
      background: $c-white;
    }

    &:first-of-type {
      @include media_for(desktop) {
        background: linear-gradient(135.44deg, rgba(103, 191, 198, 0.8) 10.76%, rgba(142, 198, 64, 0.8) 111.05%);
      }
    }

    img {
      height: 70px;
      margin: 105px auto 45px;

      @include media_for(desktop) {
        margin: inherit;
        height: 110px;
      }
    }

    h1 {
      font-size: 35px;
      line-height: 140%;
      color: $c-primary-medium-green;
      font-weight: bold;
      margin-bottom: 58px;
      display: none;

      @include media_for(desktop) {
        display: block;
      }
    }

    .forgot {
      margin: 28px auto 10px;
      cursor: pointer;
      font-size: 15px;
      line-height: 140%;
      color: $c-white;
      text-transform: uppercase;

      @include media_for(desktop) {
        color: $c-neutral-grey;
        margin: 28px auto 40px;
        text-transform: inherit;
      }
    }

    .btn {
      max-width: 100%;
      flex: 0 0 145px;
      color: $c-primary-light-green;

      @include media_for(mobile) {
        background: $c-white !important;
      }

      @include media_for(desktop) {
        flex: 0 0 160px;
        background: $c-primary-light-green;
        color: $c-white;
      }
    }

    .col-8 {
      @include media_for(mobile) {
        flex: 0 0 100%;
        margin: 10px 60px;
        max-width: calc(100vw - 120px);
      }
    }

    .form-group {
      height: auto;
      padding: 25px;

      &:before {
        top: 13px;
      }

      @include media_for(desktop) {
        padding: 13px;
        margin: 12px auto;
      }

      input {
        vertical-align: middle;
        padding-left: 25px;

        @include media_for(desktop) {
          padding-left: 35px;
        }
      }

      .error {
        @include abs_center(100%, 20px);
        top: inherit;
        bottom: -20px;
      }
    }
  }
}
