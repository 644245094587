@font-face {
  font-family: 'Roboto-Bold';
  src:  url('/assets/fonts/Roboto-Bold/Roboto-Bold.eot') format('eot'),
  url('/assets/fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'),
  url('/assets/fonts/Roboto-Bold/Roboto-Bold.ttf') format('ttf')
;
}

@font-face {
  font-family: 'Roboto-Regular';
  src:  url('/assets/fonts/Roboto-Regular/Roboto-Regular.eot') format('eot'),
  url('/assets/fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'),
  url('/assets/fonts/Roboto-Regular/Roboto-Regular.ttf') format('ttf')
;
}

@font-face {
  font-family: 'Roboto-Medium';
  src:  url('/assets/fonts/Roboto-Medium/Roboto-Medium.eot') format('eot'),
  url('/assets/fonts/Roboto-Medium/Roboto-Medium.woff') format('woff'),
  url('/assets/fonts/Roboto-Medium/Roboto-Medium.ttf') format('ttf')
;
}
