@import 'vars';
@import 'mixins';

.table {
  width: 100%;
  position: relative;
  margin-bottom: 0px;

  .dataTable.fixedHeader-floating {
    display: none !important;
  }

  .dataTables_empty {
    padding: 30px !important;
  }

  @include media_for(mobile) {
    overflow-x: scroll;
    overflow-y: hidden;
    width: auto;
  }

  .dataTable {
    min-width: 100%;
  }

  .dataTables_wrapper {
    @include media_for(mobile) {
      width: max-content;
      position: relative;
      min-width: 100%;
    }
  }

  .table-1 {
    text-align: left;
    border: 1px solid $c-neutral-20;

    @include media_for(desktop) {
      width: 100% !important;
    }

    thead {
      background: $c-neutral-off-white;

      tr {
        &:first-of-type th {
          &:last-of-type {
            padding-right: 30px;
            text-align: center;
            width: 90px !important;
          }
        }

        th {
          border: 0;
          padding: 10px 20px 10px 0;
          font-weight: normal;
          font-size: 8px;
          line-height: 140%;
          color: $c-neutral-grey;
          border-bottom: 1px solid $c-neutral-20;
          cursor: pointer;
          position: relative;
          vertical-align: middle;

          @include media_for(desktop) {
            font-size: 11px;
            line-height: 10px;
            padding: 25px 30px 25px 0;
          }

          &:first-of-type {
            padding-left: 20px;

            @include media_for(desktop) {
              padding-left: 60px;
            }
          }

          &.fix-width {
            width: auto !important;
          }

          div {
            position: relative;
            display: inline;

            .fa-chevron-down {
              @include abs_center(10px, 10px);
              color: $c-primary-light-green;
              left: inherit;
              right: -20px;
              top: 2px;
              opacity: 0;

              @include media_for(desktop) {
                top: -2px;
              }
            }
          }

          &.sorting_asc {
            .fa-chevron-down {
              opacity: 1;
            }
          }

          &.sorting_desc {
            .fa-chevron-down {
              opacity: 1;
              transform: rotate(180deg);
              top: -4px;

              @include media_for(desktop) {
                top: -2px;
              }
            }
          }
        }
      }
    }

    tbody {
      background: $c-white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

      tr {
        &:first-of-type {
          td {
            .bordered {
              margin-top: 10px;

              @include media_for(desktop) {
                margin-top: 30px;
              }
            }

            .mini-menu {
              top: 77%;
            }
          }
        }

        &:last-of-type {
          td {
            .bordered {
              margin-bottom: 10px;

              @include media_for(desktop) {
                margin-bottom: 30px;
              }
            }

            .mini-menu {
              top: 55%;
            }
          }
        }

        td {
          padding: 0;
          font-size: 8px;
          line-height: 140%;
          color: $c-neutral-60;
          border: 0;
          vertical-align: middle;
          position: relative;
          white-space: nowrap;

          @include media_for(desktop) {
            font-size: 12px;
            line-height: 15px;
          }

          &:first-of-type .bordered {
            margin-left: 10px;
            padding-left: 10px;
            border-left: 1px solid $c-neutral-20;

            @include media_for(desktop) {
              margin-left: 30px;
              padding-left: 30px;
            }
          }

          &:last-of-type .bordered {
            margin-right: 10px;
            border-right: 1px solid $c-neutral-20;

            @include media_for(desktop) {
              margin-right: 30px;
            }
          }

          .bordered {
            border-top: 1px solid $c-neutral-20;
            border-bottom: 1px solid $c-neutral-20;
            padding: 15px 0;
            display: flex;
            position: relative;
            margin: 2.5px auto;

            @include media_for(desktop) {
              padding: 29px 0;
              margin: 5px auto;
            }

            &.col-photo {
              align-items: center;
              padding: 5.5px 0;

              @include media_for(desktop) {
                padding: 14px 0;
              }

              .photo {
                margin-right: 15px;
              }
            }

            strong {
              font-family: $f-rm;
              color: $c-neutral-grey;
            }
          }
        }

        .td-switch {
          min-width: 100px !important;
        }

        .switch {
          position: relative;
          cursor: pointer;
          max-width: inherit !important;
          width: 100% !important;

          @include media_for(desktop) {
            padding-top: 27.5px !important;
            padding-bottom: 27.5px !important;
          }

          .cb {
            @include abs_center(16px, 10px);
            display: block;
            opacity: 0;
            right: inherit;
            left: 10px;
            z-index: 3;
            cursor: pointer;

            @include media_for(desktop) {
              width: 36px;
              height: 18px;
              left: 29px;
            }

            & + label {
              width: 8px;
              z-index: 10;
              position: relative;
              display: block;
              transition: $transition;
              border-radius: 100%;
              top: 1px;
              left: 1px;
              background: $c-red;
              border: 0;

              @include media_for(desktop) {
                width: 16px;
                height: 16px;
              }
            }

            &:checked + label {
              left: 7px;
              background: $c-primary-soft-green;

              @include media_for(desktop) {
                left: 17px;
              }
            }
          }

           .cbc {
            @include abs_center(16px, 10px);
            display: block;
            opacity: 0;
            right: inherit;
            left: 10px;
            z-index: 3;
            cursor: pointer;

            @include media_for(desktop) {
              width: 36px;
              height: 18px;
              left: 29px;
            }

            & + label {
              width: 8px;
              z-index: 10;
              position: relative;
              display: block;
              transition: $transition;
              border-radius: 100%;
              top: 1px;
              left: 1px;
              background: $c-red;
              border: 0;

              @include media_for(desktop) {
                width: 16px;
                height: 16px;
              }
            }

            &:checked + label {
              left: 7px;
              background: $c-primary-soft-green;

              @include media_for(desktop) {
                left: 17px;
              }
            }
          }

          &:after {
            @include abs_center(16px, 10px);
            content: '';
            right: inherit;
            left: 10px;
            background: $c-neutral-off-white;
            z-index: 1;
            border-radius: 13px;

            @include media_for(desktop) {
              width: 36px;
              height: 18px;
              left: 29px;
            }
          }
        }
      }
    }
  }

  .dataTables_paginate {
    text-align: right;
    margin-top: 25px;

    @include media_for(mobile) {
      text-align: left;
      margin-top: 15px;
    }

    .paginate_button {
      display: inline-block;
      vertical-align: top;
      width: 13px;
      height: 13px;
      border: 2px solid $c-primary-light-green;
      margin: auto 5px;
      cursor: pointer;
      font-size: 8px;
      text-align: center;
      color: $c-primary-medium-green;
      text-decoration: none;
      font-family: $f-rb;
      line-height: 140%;

      @include media_for(desktop) {
        font-size: 10px;
        width: 20px;
        height: 20px;
        line-height: 16px;
      }

      &.current {
        background: $c-primary-light-green;
        color: $c-white;
      }
    }

    .ellipsis {
      color: $c-primary-medium-green;
      font-size: 25px;
      line-height: 16px;
      vertical-align: text-top;
    }
  }
}

.dt-buttons {
  @include abs_center(310px, 20px);
  font-size: 13px;
  line-height: 20px;
  z-index: 1;
  bottom: inherit;
  left: inherit;
  top: 16px;
  text-align: right;
  padding-right: 30px;

  @include media_for(mobile) {
    top: -25px;
    right: inherit;
    left: -13px;
    font-size: 8px;
  }

  &:before {
    @include media_for(mobile) {
      width: 105px;
    }
  }

  button {
  color: $c-white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  font-weight: bold;
  border-radius: 41px;
  width: 60%;
  padding: 12px 5px;
  background: $c-primary-light-green !important;
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
    &:hover {
      background: $c-primary-medium-green !important;
    }
  }
}

.dataTables_filter {
  background: $c-white;
  padding: 15px 30px !important;
  border: 1px solid $c-neutral-20;
  border-bottom-width: 0;
  color: $c-neutral-grey;
  display: flex;
  align-items: center;

  @include media_for(mobile) {
    margin-top: 20px;
    padding: 9px 15px !important;
  }

  &.custom-filter {
    label, .p-autocomplete, .p-calendar {
      width: 150px;
      margin: auto 8px;

      &:first-of-type {
        margin-left: 0px;
      }
    }

    .p-calendar {
      width: 195px;
    }

    &.wo-border {
      border-bottom: 0 !important;
    }
  }

  &.custom-filter-ships {
    border-top: 0;
    padding-top: 0;
    overflow-x: auto;

    @include media_for(mobile) {
      padding-bottom: 20px !important;
    }

    div {
      background: $c-neutral-off-white;
      border-radius: 43px;
      font-size: 12px;
      line-height: 16px;
      padding: 6px 14px;
      margin: auto 7px;
      display: inline-flex;
      flex: 0 0 auto;

      @include media_for(mobile) {
        font-size: 8px;
      }

      &:first-of-type {
        margin-left: 0px;
      }

      span {
        font-size: 14px;
        color: $c-primary-light-green;
        position: relative;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  label, .p-autocomplete, .p-calendar, .p-dropdown {
    height: 30px;
    border: 1px solid $c-primary-light-green;
    border-radius: 43px;
    width: 300px;
    position: relative;
    text-align: left;
    margin: 0;
    vertical-align: text-top;
    color: $c-neutral-60;

    @include media_for(mobile) {
      width: 72vw;
      height: 20px;
    }

    input {
      @include abs_center(calc(100% - 35px), 100%);
      width: calc(100% - 35px);
      min-width: 114px;
      left: inherit;
      border: 0;
      font-size: 12px;
      line-height: 28px;
      border-radius: 43px;

      @include media_for(mobile) {
        font-size: 8px;
        width: calc(100% - 20px);
      }
    }

    &:before, .search:before {
      @include abs_center(12px, 12px);
      font-family: 'Font Awesome 5 Free', serif;
      font-weight: 900;
      content: '\f002';
      right: inherit;
      top: 12px;
      left: 12px;
      font-size: 12px;
      line-height: 0;
      z-index: 1;

      @include media_for(mobile) {
        width: 6px;
        height: 6px;
        font-size: 6px;
        top: 6px;
        left: 10px;
      }
    }
  }

  label.search {
    margin-right: 8px !important;

    @include media_for(desktop) {
      margin-right: 40px !important;
    }

    &:before {
      content: '\f002' !important;
    }
  }

  .p-dropdown {
    width: 200px;

    .p-dropdown-items-wrapper {
      overflow: hidden;
    }

    .p-dropdown-label {
      width: 100%;
      z-index: 1;
      padding: 4px 35px;
    }

    .p-dropdown-trigger {
      width: 30px;

      .pi {
        z-index: 2;

        &:after {
          font-family: 'Font Awesome 5 Free', serif;
          font-weight: 900;
          content: '\f078';
          font-size: 12px;
        }
      }
    }
  }
}

.tab-table {
  padding: 0;

  @include media_for(mobile) {
    background: $c-white;
  }

  >.col-12 {
    padding: 0;
  }

  .dataTables_filter {
    border-top: 0;

    @include media_for(mobile) {
      margin-top: 0px;
      border-right-width: 0;
    }

    label {
      width: 90px;
      margin-right: 0px;

      @include media_for(desktop) {
        width: 145px;
      }

      &:before {
        content: unset;
      }

      input {
        left: 0;
        right: 0;
        width: 100%;
        margin: auto;
        text-align: center;
      }
    }

    .custom-action {
      border: 1px solid #8EC640;
      border-radius: 43px;
      height: 20px;
      color: $c-neutral-60;
      line-height: 18px;
      width: 40px;
      text-align: center;
      cursor: pointer;
      margin-left: 15px;
      transition: $transition;

      @include media_for(desktop) {
        height: 30px;
        width: 65px;
        line-height: 28px;
        margin-left: 30px;
      }

      &:hover {
        color: $c-white;
        background: $c-primary-light-green;
      }
    }
  }

  .dt-buttons {
    @include media_for(mobile) {
      top: -3.5%;
      left: inherit;
      right: 0px;
      padding-right: 10px;
    }

    &:before {
      @include media_for(mobile) {
        width: 140px;
      }
    }
  }

  .table-1 {
    @include media_for(mobile) {
      margin-top: 0px;
    }
  }

  .dataTables_paginate {
    @include media_for(mobile) {
      margin-top: 0px;
      padding-top: 15px;
      background: $c-neutral-off-white;
    }
  }
}

.table-group {
  .custom-filter {
    border-right: 0.5px solid $c-neutral-20;

    label {
      margin-right: 23px;

      @include media_for(desktop) {
        width: 145px !important;
        margin-right: 23px;
      }

      &:before {
        @include abs_center(12px, 12px);
        font-family: 'Font Awesome 5 Free', serif;
        font-weight: 900;
        content: '\f002';
        right: inherit;
        line-height: 0;
        z-index: 1;
        width: 6px;
        height: 6px;
        font-size: 6px;
        top: 6px;
        left: 10px;

        @include media_for(desktop) {
          width: 12px;
          height: 12px;
          font-size: 12px;
          top: 12px;
          left: 12px;
        }
      }

      input {
        padding-left: 20px;
      }
    }
  }

  .table {
    overflow: hidden;
    min-height: 435px;

    .dt-buttons {
      top: -7.5%;

      @include media_for(desktop) {
        top: 18.1px;
      }
    }
  }

  .table-1 {
    width: 100%;
    max-width: 100%;
  }

  thead {
    tr {
      th {
        &.mobile {
          width: 100% !important;
          border-right: 1px solid #D1D3D4;
          border-top: 1px solid #D1D3D4;

          div {
            display: flex;
            flex: 1;
            justify-content: center;

            em {
              right: 5%;
            }
          }
        }

        &.blank {
          padding: 0 !important;
        }

        @include media_for(mobile) {
          display: inline-flex;
          flex: 1;
          width: 25% !important;
          justify-content: center;
          padding-right: 0 !important;
          padding-left: 0 !important;

          .ql-2 {
            padding-right: 5px;
          }

          &:first-of-type {
            padding-left: 10px !important;
          }

          &:last-of-type:not(.blank) {
            padding-right: 10px !important;
            width: auto !important;
          }
        }

        &.big {
          text-align: center;
          padding: 10px 30px;
          border-right: 1px solid #D1D3D4;

          @include media_for(mobile) {
            display: none;
          }

          &.left {
            border-left: 1px solid #D1D3D4;
          }
        }

        .ql {
          padding-left: 5px !important;
        }

        &.q {
          width: 2.5% !important;
          padding: 10px 0px !important;
          border-right: 1px solid #D1D3D4;
          text-align: center;

          @include media_for(mobile) {
            padding: 10px 5px !important;
          }

          &:first-of-type {
            border-left: 1px solid #D1D3D4;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &.mobile {
        &:first-of-type {
          td {
            padding-top: 10px !important;
          }
        }

        &:last-of-type {
          td {
            padding-bottom: 10px !important;
          }
        }

        td {
          padding: 5px 10px;
        }

        .data {
          display: flex;
          border: 0.5px solid $c-neutral-20;
          border-bottom: 0;

          div {
            display: inline-flex;
            flex: 1;
            color: $c-neutral-grey;
            font-weight: bold;
            padding: 10px 5px;

            &:nth-of-type(3) {
              font-weight: normal;
              color: $c-neutral-60;
            }

            &.score {
              border-left: 0.5px solid $c-neutral-20;
            }
          }
        }

        .questions {
          display: flex;

          article {
            display: inline-flex;
            justify-content: center;
            background: $c-neutral-off-white;
            color: $c-neutral-grey;
            font-weight: bold;
            border-bottom: 0.5px solid $c-neutral-20;

            &.t {
              border-top: 0.5px solid $c-neutral-20;
              padding:5px 0;
            }

            &:first-of-type {
              width: 60%;
              border-right: 0.5px solid $c-neutral-20;
              border-left: 0.5px solid $c-neutral-20;
            }

            &:last-of-type {
              width: 40%;
              border-right: 0.5px solid $c-neutral-20;
            }

            &.answer {
              background: $c-white;
            }

            .qd {
              padding: 3px 0 !important;
            }

            div {
              display: inline-flex;
              margin: 0 !important;
              padding: 0 !important;
              flex: 1;
              border: 0;
              border-right: 0.5px solid $c-neutral-20;

              &.last {
                border-right: 0;
              }
            }
          }
        }

        @include media_for(desktop) {
          display: none;
        }
      }

      td {
        a {
          text-decoration: none;
        }

        &:nth-of-type(1), &:nth-of-type(2) {
          .bordered {
            color: $c-neutral-grey;
          }
        }

        .bordered {
          @include media_for(desktop) {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
          }

          &.ql {
            padding-left: 5px !important;
            padding-right: 5px !important;
            color: $c-neutral-60;
          }
        }

        .qd {
          border-right: 1px solid #D1D3D4;
          justify-content: center;
          color: $c-neutral-grey;

          &.left {
            border-left: 1px solid #D1D3D4;
          }
        }

        &:last-of-type {
          div {
            justify-content: center;
          }
        }
      }
    }
  }
}


          .cb {
            display: none;

            &+ label {
              width: 8px;
              height: 8px;
              border: 1px solid $c-neutral-40;
              display: block;
              cursor: pointer;
              background: $c-neutral-off-white;
              margin: 1px auto 2px 0;

              @include media_for(desktop) {
                width: 13px;
                height: 13px;
                margin: 0 auto 2px 0;
              }
            }

            &:checked + label {
              background: $c-primary-light-green;
            }
          }


          .cbc {
            display: none;

            &+ label {
              width: 8px;
              height: 8px;
              border: 1px solid $c-neutral-40;
              display: block;
              cursor: pointer;
              background: $c-neutral-off-white;
              margin: 1px auto 2px 0;

              @include media_for(desktop) {
                width: 13px;
                height: 13px;
                margin: 0 auto 2px 0;
              }
            }

            &:checked + label {
              background: $c-primary-light-green;
            }
          }

