@import 'vars';
@import 'mixins';

.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(65, 64, 66, 0.7);
  justify-content: center;
  align-items: center;
  visibility: hidden;
  display: none;
  transition: $transition;

  &.show {
    display: flex;
    visibility: visible;
  }

  .row {
    background: white;
    width: 100%;
    max-width: 800px;
    padding: 0 20px;
    margin: auto 20px;
    position: relative;

    @include media_for(desktop) {
      padding: 0 40px;
    }

    &.header-content {
      padding-top: 60px;

      @include media_for(desktop) {
        padding-top: 70px;
      }

      .circle {
        display: none !important;;
      }
    }

    &.header-two {
      padding-top: 80px;

      @include media_for(desktop) {
        padding-top: 240px;
      }

      .header {
        @include media_for(desktop) {
          height: 120px;
        }
      }

      .circle {
        @include media_for(desktop) {
          display: block;
        }
      }
    }
  }

  .btn {
    @include media_for(desktop) {
      margin-bottom: 40px;
    }

    &.first-btn {
      @include media_for(mobile) {
        margin-bottom: -15px;
      }
    }
  }

  .header {
    @include abs_center(100%, 40px);
    bottom: inherit;
    display: block;
    width: 100%;
    background: linear-gradient(90deg, #67BFC6 -0.02%, #8EC640 99.98%);

    em {
      @include abs_center(10px, 10px);
      color: $c-white;
      left: inherit;
      cursor: pointer;
      font-size: 10px;
      right: -85%;

      @include media_for(desktop) {
        width: 15px;
        height: 18px;
        font-size: 20px;
        right: -82%;
      }
    }
  }

  .circle {
    @include abs_center(60px, 60px);
    border-radius: 100%;
    background: $c-neutral-off-white;
    z-index: 1;
    bottom: inherit;
    top: 10px;
    overflow: hidden;
    cursor: pointer;
    display: block;

    @include media_for(desktop) {
      width: 160px;
      height: 160px;
      top: 40px;
    }

    &:before {
      @include abs_center(14px, 19px);
      content: '+';
      font-size: 25px;
      color: $c-neutral-40;

      @include media_for(mobile) {
        content: '';
      }
    }

    input {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }

    .preview {
      img {
        @include abs_center(100%, auto);
        z-index: -1;
      }
    }
  }
}
