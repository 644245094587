@import 'vars';

.p-datepicker {
  background: $c-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid $c-neutral-20;
  top: 40px !important;

   

  .p-datepicker-title {
    span {
      margin: auto 5px;
    }

    select {
      border: 0px;
      margin: auto 5px;
    }
  }
  .p-datepicker-header {
    padding: 15px;
  }


  .p-highlight {
    background: $c-primary-light-green;
    color: $c-white;
  }

  .p-disabled {
    color: $c-neutral-20;
    cursor: initial;
  }

  table{
    thead {
      background: $c-neutral-off-white;
      th {
        border-top: 1px solid $c-neutral-20;
        border-bottom: 1px solid $c-neutral-20;
      }
    }
     tbody {
      td {
        padding: 0.5rem;
        span {
          border-radius: 100%;
          height: 25px;
          width: 22px;
          position: relative;
          display: block;
          line-height: 24px;
          text-align: center;
          color: $c-neutral-grey;
        }
      }
    }
  }

    .p-datepicker-buttonbar {
    button {
      width: auto;
      margin: 0 10px 10px;
      padding: 0px 20px 20px;
      border-radius: 43px;

      &:after {
        content: unset;
      }

      &:hover {
        color: white;
      }
    }
  }

    button {
    //width: 20px;
    //height: 20px;
    //border-radius: 100%;
   // transition: $transition;
   // position: relative;
   // border: 1px solid $c-primary-light-green;
   // outline: none;

    &:hover {
      background: $c-primary-light-green;

      &:after {
        color: $c-white;
      }
    }

    &:after {
      @include abs_center(100%, 100%);
     // content: '◄';
      left: -2px;
      font-weight: bold;
      font-size: 11px;
      color: $c-primary-light-green;
    }

    &.p-datepicker-next:after {
     // content: '►';
      right: -4px;
    }
  }
}