@import 'vars';
@import 'mixins';

header {
  height: 55px;
  position: relative;
  background: linear-gradient(90deg, #67BFC6 -0.02%, #8EC640 99.98%);

  @include media_for(desktop) {
    height: 85px;
  }

  .logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 100%;
    padding: 20px 0 20px 20px;

    @include media_for(mobile) {
      @include abs_center(100%, 21px);
    }

    img {
      display: block;
      margin-right: auto;
      height: 30px;

      @include media_for(mobile) {
        @include abs_center(auto, 21px);
      }
    }
  }

  .user-data {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10px 20px;

    @include media_for(desktop) {
      width: calc(100vw - 140px);
      padding: 20px 55px 20px 0px;
    }

    .fas {
      position: absolute;
      left: 20px;
      transition: $transition;
      font-size: 18px;

      @include media_for(desktop) {
        display: none;
      }

      &.fa-times {
        color: $c-white;
        z-index: 15;
      }
    }

    .name {
      color: $c-white;
      font-weight: normal;
      font-size: 13px;
      padding: 0px 25px;
      position: relative;

      @include media_for(mobile) {
        display: none;
      }

      &:after {
        @include abs_center(1px, 85px);
        content: '';
        background: white;
        right: inherit;
      }
    }

  }
}
